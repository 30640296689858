<template>
  <div>
    <PDropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :virtualScrollerOptions="virtualScrollerOptions"
      :editable="false"
      :emptySelectionMessage="$t('field_select_empty_selection')"
      :emptyFilterMessage="$t('field_select_filter_empty_items')"
      :emptyMessage="$t('field_select_empty_items')"
      :tabindex="props.column.fieldInfo.meta.sortPosition"
      :aria-label="props.column.fieldInfo.name"
      :class="['w-full']"
      inputClass="form-select"
      :loading="isItemsLoading"
      :placeholder="
        isItemsLoading ? $t('items_is_loading') : $t('field_select_empty_selection')
      "
      :disabled="isItemsLoading || props.column.fieldInfo.meta.isReadonly"
      :modelValue="props.filter.value"
      @update:modelValue="onUpdateModelValue"
      v-on="dropdownEventListeners"
    >
      <template #header>
        <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            <input
              type="text"
              autocomplete="off"
              role="search"
              :value="search"
              :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
              v-on="searchEventListeners"
            />

            <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
          </div>
        </div>
      </template>

      <template #value="slotProps">
        <div
          v-if="!!itemsHashMap[slotProps.value] && !!slotProps.value"
          class="flex items-center justify-between"
        >
          <RenderTemplate
            :collectionName="relationInfo?.relatedCollection ?? ''"
            :item="itemsHashMap[slotProps.value]"
            :fieldInfo="props.column.fieldInfo"
            :defaultTemplate="defaultRenderTemplate ?? ''"
          />
        </div>

        <template v-else>
          <span>{{ slotProps.placeholder }}</span>
        </template>
      </template>

      <template #option="slotProps">
        <RenderTemplate
          v-if="!!itemsHashMap[slotProps.option.value]"
          :collectionName="relationInfo?.relatedCollection ?? ''"
          :item="itemsHashMap[slotProps.option.value]"
          :fieldInfo="props.column.fieldInfo"
          :defaultTemplate="defaultRenderTemplate"
        ></RenderTemplate>

        <template v-else>
          {{ slotProps.option.label }}
        </template>
      </template>
    </PDropdown>
  </div>
</template>

<script setup lang="ts">
interface _VTI_TYPE_DataTableFilterMetaData {
    /**
     * Filter value
     */
    value: any;
    /**
     * Filter match mode
     */
    matchMode: 'startsWith' | 'contains' | 'notContains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'between' | 'dateIs' | 'dateIsNot' | 'dateBefore' | 'dateAfter' | string | undefined;
}
type _VTI_TYPE_Column = {
  name: string;
  fieldInfo: FieldInfoInterface;
}
interface ColumnFilterEmits {
  (e: "update:filter", payload: UpdateFilterEmitPayload): void;
}
interface ColumnFilterProps {
  collectionName: string;
  column: _VTI_TYPE_Column;
  filter: _VTI_TYPE_DataTableFilterMetaData;
  matchMode?: FilterMatchModeOptions[keyof FilterMatchModeOptions];
}
  import { computed } from 'vue'
  import PDropdown from 'primevue/dropdown'
  import CollectionInterface from '~/api/collections/entities/CollectionInterface'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import { useDropdownItemsController } from '~/service/dropdown-items/composables/useDropdownItemsController'
  import { RenderTemplate } from '~/entities/render-template'
  import { useRelationM2O } from '~/api/relations/composables/useRelationsM2O'
  import { useCollecitonsStore } from '~/stores/collections'
  import { ColumnFilterMeta } from '../../lib/interfaces'
  type LinearModelValue = (string | number)[];
  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();
  const { relationInfo, relatedCollection } = useRelationM2O(
    computed(() => props.collectionName),
    computed(() => props.column.fieldInfo),
  );
  const collectionStore = useCollecitonsStore();
  const collection = computed<CollectionInterface>(() => {
    return collectionStore.getCollection(props.collectionName) as CollectionInterface;
  });
  const {
    items,
    options,
    search,
    isLoading: isItemsLoading,
    virtualScrollerOptions,
    searchEventListeners,
    dropdownEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.column.fieldInfo),
  );
  const itemsHashMap = computed<Record<ItemInterface["id"], ItemInterface>>(() =>
    items.value.reduce<Record<ItemInterface["id"], ItemInterface>>((hashMap, item) => {
      if (item.id in hashMap) return hashMap;
      hashMap[item.id] = item;
      return hashMap;
    }, {}),
  );
  const defaultRenderTemplate = computed<string>(() => {
    const fieldTemplate = props.column.fieldInfo.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;
    const collectionTemplate = collection.value?.meta.displayTemplate;
    const primaryField = collection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    );
    return collectionTemplate || `{{ ${primaryField?.name} }}`;
  });
  const onUpdateModelValue = (event: LinearModelValue): void => {
    emit("update:filter", {
      filterName: props.column.name,
      data: event,
      filterMeta: {
        [ColumnFilterMeta.API_FILTER_NAME]: props.column.fieldInfo.name,
      },
      immediate: true,
    });
  };
</script>

<style scoped></style>

